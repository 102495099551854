.ant-modal-content{
    border-radius: 15px;
    overflow: hidden;
}

.bpCalendar {
    width: 1350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    line-height: 1.125em;
}