
.dndItemWrap {
    min-width: 200px;
    display: flex;
    position: relative;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-color: #eaeaea;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}


.dndItemWrap-in {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
}


.dndItemWrap-select {
    background-color: #ff00ff;
}


.dndItemWrap-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 10px;
}

.dndItemWrap-row {
    width: 92%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dndItemWrap-todoBox {
    width: 92%;
    padding: 5px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    margin-top: 10px;
    background-color: #e6ffec;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.dndItemWrap-todoBox::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}


.dndItemWrap-todoRowBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px 8px;
}


.dndItemWrap-todoRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dndItemWrap-todoRow-tStatus {
    font-size: 1rem;
    color: #ffffff;
    background-color: #c58ddd;
    padding: 3.4px 7px;
    border-radius: 3px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dndItemWrap-todoRow-txt {
    font-size: 1rem;
    line-height: 1rem;
    color: #3e3e3e;
    margin-left: 7px;
}



.dndItemWrap-boardBox {
    width: 92%;
    display: flex;
    flex-direction: column;
    min-height: 90px;
    max-height: 120px;
    overflow-y: auto;
    border-radius: 5px;
    background-color: #efefef;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 10px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.dndItemWrap-boardBox::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.dndItemWrap-boardBox-item {
    width: 100%;
    padding: 7px 10px;
    border-width: 0px;
    border-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #d8d8d8;
    display: flex;
    flex-direction: column;
}

.dndItemWrap-boardBox-txt {
    font-size: 1rem;
    line-height: 1.1rem;
    color: #3e3e3e;
}

.dndItemWrap-boardBox-date {
    width: 100%;
    font-size: 1rem;
    color: #6c6c6c;
    margin-top: 3px;
    display: flex;
    justify-content: flex-end;
}

.dndItemWrap-img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.dndItemWrap-status {
    border-width: 0px;
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
}

.dndItemWrap-cName {
    border-width: 0px;
    font-size: 1rem;
    color: #313131;
}

.dndItemWrap-del {
    font-size: 1rem;
    color: #df0000;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.modalProgramDndIndex {
    font-size: 1rem;
    color: #000000;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
}

.dndItem-key {
    font-size: 0.8rem;
    color: #ffffff;
    text-align: center;
    margin-left: 5px;
}

.dndItemWrap-todoRow-ta {
    border-width: 0px;
    width: 100%;
    height: auto;
    font-size: 1rem;
    color: #000000;
    margin-top: 7px;
    padding: 8px 7px;
    resize: none;
    max-height: 100px;
}