.container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: auto;
    /* overflow-x: hidden; */
    overflow-x: auto;
    width: 100%;
    height: 100vh;
    padding-top: 65px;
}



.container-wrap {
    width: 100%;
    /* width: calc(100% - 40px); */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.row-wrap {
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    /* width: calc(100% - 40px); */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
}

.bt-progressWrap {
    width: 100%;
    height: 16px;
    margin-left: 14px;
    margin-right: 14px;
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-radius: 10px;
    flex-direction: row;
    overflow: hidden;
}

.bt-progress {
    display: flex;
    height: 100%;
}

.bt-question-title {
    font-size: 1.8em;
    color: #1f1f1f;
    font-weight: bold;
}

.bt-question-sub {
    font-size: 1.3em;
    color: #797979;
}

.bt-question-con {
    font-size: 1.3em;
    color: #3f3f3f;
    font-weight: bold;
    line-height: 1.6em;
}

.bt-answerBox {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    background-color: #f2bb3d;
    box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 1;
}

@media (hover: hover) {
    .bt-answerBox:hover {
        opacity: 0.7;
    }
}

.bt-answerText {
    font-size: 1.1rem;
    color: #ffffff;
    text-align: center;
    line-height: 1.4em;
}

.bt-listWrap {
    min-width: 250px;
    min-height: 160px;
    margin-bottom: 30px;
    margin-left: 6px;
    margin-right: 6px;
    box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}


.bt-listImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.b-width-100 {
    width: 100%;
}

.b-width-25 {
    width: calc(25% - 13px);
}

.bt-listTitleWrap {
    width: 100%;
    min-height: 40px;
    padding-top: 12px;
    padding-bottom: 12px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.40);
    bottom: 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.bt-listTitleWrapText {
    font-size: 1em;
    color: #ffffff;
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
}