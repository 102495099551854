body {
    touch-action: none;
}

.dot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    display: flex;
    font-size: 0.4rem;
    line-height: 0.7rem;
    font-weight: bold;
    color: #ff8000;
    padding-top:1px;
}

.calitem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.NavTopBar {
    width: 100%;
    position: fixed;
    z-index: 90;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: flex-start;
}

.NavTopBar-sideOpen {
    width: calc(100% - 224px);
    transition: all .2s ease-in;
}

.NavTopBar-on-btnBox {
    width: 100%;
    position: fixed;
    z-index: 99;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;
    display: flex;
    transition: all .1s ease-in;
}

.NavTopBar-on-btnBox-sideOpen {
    width: calc(100% - 224px);
    transition: all .2s ease-in;
}


.NavTopBar-logout {
    font-size: 0.8rem;
    cursor: pointer;
    /* border-style: solid;
    border-width: 1px;
    border-color: #cfcfcf;
    border-radius: 2px; */
    color: #787878;
    padding: 4px 8px;
    margin-right: 15px;
}

.appContainer {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    margin-left: 0px;
    transition: all .1s ease-in;
}

.appContainer-sideOpen {
    width: calc(100% - 224px);
    margin-left: 224px;
    transition: all .2s ease-in;
}



.contentsBox {
    width: 100%;
}

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    /* overflow-x: hidden; */
    overflow-x: auto;
    width: 100%;
    height: 100vh;
    padding-top: 65px;
}

.container-zero {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    /* overflow-x: hidden; */
    overflow-x: auto;
    width: 100%;
    height: 100vh;
}

.container-zero-row {
    display: flex;
    position: relative;
    flex-direction: row;
    flex: 1;
    /* overflow-x: hidden; */
    overflow-x: auto;
    width: 100%;
    height: 100vh;
}

.sbvideoplay-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 80px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sbvideoplay-left::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

.sbvideoplay-leftBtnWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    min-height: 44px;
    border-radius: 25px;
    background-color: #fcc01c;
    margin-top: 20px;
    color: #000000;
    font-weight: bold;
    font-size: 1.4rem;
    cursor: pointer;
    padding-top: 3px;
}

.sbvideoplay-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    flex: 1;
    height: 100vh;
}

.container-row {
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.container-wrap {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    /* width: calc(100% - 40px); */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}


.sbday-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94%;
    padding: 7px 0px;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    border-color: #e3e3e3;
}

.sbday-text {
    font-size: 0.9rem;
    color: #2f2f2f;
    text-align: center;
}

.container-title {
    font-size: 1.5rem;
    font-weight: normal;
    margin-left: 6px;
    color: #542000;
}

.container-sub {
    font-size: 1.1rem;
    font-weight: normal;
    margin-left: 8px;
    color: #808080;
    margin-top: 7px;
}

.itembox01 {
    min-width: 250px;
    min-height: 100px;
    margin-bottom: 30px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #ffffff;
    box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
}

.itembox02 {
    min-width: 250px;
    min-height: 100px;
    margin-bottom: 30px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #ffffff;
    box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
}

.itembox03 {
    min-width: 230px;
    min-height: 160px;
    max-height: 160px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: 6px;
    margin-right: 6px;
    background-color: #f2bb3d;
    box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.itemBoxModal {
    max-width: 160px;
    min-width: 160px;
    min-height: 85px;
    max-height: 85px;
    overflow: hidden;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 8px;
    background-color: #f2bb3d;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
}

.itemBoxModal-bottomBtnWrap-btnText {
    font-size: 0.74rem;
    font-weight: bold;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: #cfcfcf;
    padding: 2px 6px;
    border-radius: 4px;
}

.itembox01-topNotiWrap {
    display: inline-flex;
    padding: 5px 10px;
    border-radius: 30px;
    margin-bottom: 12px;
    font-size: 0.8rem;
    color: #ffffff;
    font-weight: bold;
    margin-left: -2px;
}

.itembox01-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: #210e05;
    margin-bottom: 15px;
}

.itembox01-sub01 {
    font-size: 0.85rem;
    color: #4d4d4d;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 11px;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    border-color: #ebebeb;
}

.itembox02-scrollview{
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
}

.itembox02-title02 {
    font-size: 1.1rem;
    font-weight: bold;
    color: #ffffff;
    width: 100%;
    padding: 16px 20px;
    margin-bottom: 15px;
}

.itembox05-title02 {
    font-size: 1.1rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
}

.itembox02-sub02-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    justify-items: flex-start;
    width: calc(100% - 40px);
    /* width:100%; */
    margin-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    border-color: #ebebeb;
}

.itembox01-sub02 {
    font-size: 0.9rem;
    color: #383838;
}

.itembox01-sub03 {
    font-size: 0.85rem;
    color: #4d4d4d;
    margin-left: 4px;
    cursor: pointer;
}

.itembox01-sub03:hover {
    color: #0000ff;
}

.itembox01-bottomBtnWrap {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
}

.itembox02-bottomBtnWrap {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 15px;
}

.itembox03-bottomBtnWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.itembox03-title01 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
}

.itembox03-title02 {
    font-size: 0.8rem;
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
}

.itembox01-bottomBtnWrap-btnText {
    font-size: 0.74rem;
    font-weight: bold;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: #cfcfcf;
    padding: 5px 10px;
    border-radius: 4px;
}


.bgMain {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../img/bg_gym.jpg');
    /* background-size:100% 100%; */
}

.loginBox {
    display: flex;
    position: relative;
    width: calc(100% - 40px);
    max-width: 400px;
    height: 320px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
    box-shadow: 0 10px 45px 0 rgba(0, 0, 0, 0.90);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.container-login {
    display: flex;
    position: relative;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginLogo {
    font-size: 1.4em;
    font-weight: bold;
    color: #000000;
    margin-top: 15px;
}

.loginIpBox {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    width: 86%;
    padding: 10px 0 10px 0;
    margin-top: 25px;
    border-radius: 10px;
}

.loginIpTitle {
    width: 80px;
    padding-left: 25px;
    font-size: 0.9rem;
    color: #292929;
}

.loginIpId {
    border-width: 0px;
    width: 100%;
    height: 100%;
    font-size: medium;
    color: #000000;
}

.loginIpWrap {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 10px 0;
}

.loginBtn {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #db8400;
    width: 86%;
    height: 46px;
    margin-top: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.loginBtnTxt {
    color: #ffffff;
    font-size: large;
    font-weight: bold;
}

.sideBox {
    width: 224px;
    left: -224px;
    transition: left .1s ease-in;
    position: fixed;
    border-style: solid;
    border-right-width: 1px;
    border-color: #e6e6e6;
    box-sizing: border-box;
    background-image: url('../img/bg_nav2.jpg');
}

.sideBox-open {
    left: 0px;
    transition: left .2s ease-in;
}

.scrollY-box {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100vh;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollY-box::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

.NavSide-img {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    color: #ffffff
}

.NavSide-box {
    width: 100%;
    align-items: center;
    display: flex;
    padding-left: 22px;
    height: 56px;
    border-width: 0px;
    border-bottom-width: 0.8px;
    border-color: #6b6b6b;
    border-style: solid;
    cursor: pointer;
    color: #cfcfcf;
}

.NavSide-box:hover {
    color: #c4793b;
}

.NavSide-logoTxt {
    font-size: 0.9rem;
    font-weight: normal;
}

.titleBtn-Con {
    display: flex;
    height: 34px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f7f8ff;
    border-width: 1px;
    border-style: solid;
    border-color: #449e51;
    color: #2a7335;
    padding: 0px 20px;
    border-radius: 21px;
    box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
    margin-right: 12px;
    cursor: pointer;
    overflow: hidden;
}

.titleBtn-Con-del {
    display: flex;
    height: 34px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f7f8ff;
    border-width: 1px;
    border-style: solid;
    border-color: #d60a0a;
    color: #d60a0a;
    padding: 0px 20px;
    border-radius: 21px;
    box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
    margin-right: 12px;
    cursor: pointer;
    overflow: hidden;
}

.sbdayprogram-menuBtn-off {
    min-width: 120px;
    font-size: 1rem;
    color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 8px 10px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.sbdayprogram-menuBtn-on {
    min-width: 120px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffae3c;
    border-style: solid;
    border-width: 1px;
    border-color: #ffae3c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 8px 10px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}


.sbdayprogram-dayTiTleText {
    font-size: 1rem;
    color: #ffffff;
}

.sbdayprogram-calBox-on {
    color: #000000;
    background-color: #ff9d26;
    border-color: #ff9d26;
    font-weight: bold;
}

.sbdayprogram-calBox-off {
    color: #ffffff;
    font-weight: normal;
    border-color: #ffffff;
}

.sbdayprogram-calBox {
    min-width: 50px;
    padding: 10px 13px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
}

.sbdayprogram-calBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}





.titleBtn-text {
    font-size: 0.8rem;
}


.searchBox-Con {
    display: flex;
    height: 34px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8ff;
    border-width: 1px;
    border-style: solid;
    border-color: #8a8aff;
    padding-left: 7px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 21px;
    box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
}

.searchBox-IpWrap {
    display: flex;
    position: relative;
    flex: 1;
    font-size: 0.8rem;
    flex-direction: row;
    align-items: center;
    border-width: 0;
    background-color: #f7f8ff;
    padding-left: 13px;
    width: 120px;
}

.searchBox-ImgBtn {
    width: 22px;
    height: 22px;
    margin-right: 5px;
}

.solidSelect {
    border-radius: 0;
    /* 아이폰 사파리 보더 없애기 */
    -webkit-appearance: none;
    /* 화살표 없애기 for chrome*/
    -moz-appearance: none;
    /* 화살표 없애기 for firefox*/
    appearance: none;
    /* 화살표 없애기 공통*/
    border-width: 0px;
    background-color: #ffffff;
}

.solidSelect::-ms-expand {
    display: none
        /* 화살표 없애기 for IE10, 11*/
}

.modalSaveBtn {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    background-color: #f6b533;
    border-color: #f6b533;
    border-style: solid;
    border-width: 2px;
    width: 100%;
    height: 46px;
    margin-top: 30px;
    border-radius: 23px;
    cursor: pointer;
}

.noticeTa {
    border-width: 0px;
    width: 100%;
    font-size: small;
    color: #000000;
    margin-top: 5px;
    padding-top: 5px;
    resize: none;
}

.modalIp {
    border-width: 0px;
    width: 100%;
    height: 28px;
    font-size: small;
    color: #000000;
    padding-left: 10px;
}

.modalSp {
    border-width: 0px;
    width: 100%;
    font-size: small;
    color: #000000;
    margin-top: 5px;
    white-space: pre-wrap;
}

.menuBtnWrap {
    display: flex;
    width: 80%;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-color: #c7c7c7;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    cursor: pointer;
}

.menuBtnTxt {
    font-size: medium;
    color: #000000;
    font-weight: normal;
}

.modalIpWrap {
    width: 100%;
    padding-bottom: 5px;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #e0e0e0;
    margin-bottom: 15px;
}

.modalIpWrapCenter {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-items: center;
    border-width: 0 0 0 0;
}

.modalIpWrapColumn {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
    border-width: 0 0 0 0;
}

.modalIpTitle {
    min-width: 75px;
    font-size: 0.95em;
    color: #000000;
    font-weight: bold;
}

.modalIpTitle2 {
    font-size: small;
    color: #000000;
    font-weight: bold;
}

.modalProgramDndWrap {
    width: 31%;
    margin-right: 1%;
    margin-left: 1%;
    display: flex;
    position: relative;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-color: #dbdbdb;
    padding: 5px 5px;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #ffffff;
}

.modalProgramDndWrap-row {
    width: 104px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modalProgramDndIndex {
    font-size: 0.95em;
    color: #000000;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
}

.modalProgramDndKey {
    font-size: 0.95em;
    color: #000000;
    font-weight: bold;
    text-align: center;
    margin-left: 5px;
}

.modalProgramDndTxtDel {
    font-size: 0.8em;
    color: #df0000;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.modalProgramDndIp {
    border-width: 0px;
    width: calc(100%-11px);
    padding-left: 6px;
    padding-right: 5px;
    font-size: 0.85em;
    color: #3e3e3e;
    margin-top: 4px;
    margin-bottom: 3px;
}

.modalProgramDndImg {
    width: calc(100%-10px);
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 8px;
    object-fit: cover;
}



.filterBtn {
    display: flex;
    height: 42px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8ff;
    border-width: 1px;
    border-style: solid;
    border-color: #8a8aff;
    padding-left: 7px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    margin-right: 6px;
    margin-left: 6px;
    border-radius: 21px;
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
}

.titleBtn-calendarIp {
    border-width: 0px;
    width: 100%;
    height: 100%;
    font-size: small;
    color: #000000;
    padding-left: 10px;
    background-color: #f7f8ff;
}

.solidSelect {
    border-radius: 0;
    /* 아이폰 사파리 보더 없애기 */
    -webkit-appearance: none;
    /* 화살표 없애기 for chrome*/
    -moz-appearance: none;
    /* 화살표 없애기 for firefox*/
    appearance: none;
    /* 화살표 없애기 공통*/
    border-width: 0px;
}

.solidSelect::-ms-expand {
    display: none
        /* 화살표 없애기 for IE10, 11*/
}

.Sales-price {
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 6px;
    margin-right: 35px;
    color: #5e5e5e;
    margin-bottom: 20px;
    border-width: 0px;
    border-bottom-width: 1px;
    border-color: #5e5e5e;
    border-style: solid;
    padding-bottom: 12px;
    cursor: pointer;
}

















.programAddContents {
    width: 100%;
    height: 28px;
    font-size: 1.2em;
    color: #000000;
    padding-left: 10px;
    border-width: 0px;
}


.programAddTitle {
    padding-left: 5px;
    min-width: 75px;
    font-size: 1.2em;
    color: #000000;
    font-weight: bold;
}








.videoDndWrap {
    min-width: 200px;
    display: flex;
    position: relative;
    flex-direction: column;
    border-width: 1px;
    border-style: solid;
    border-color: #dbdbdb;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}


.videoDndWrap-in {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-right: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
}


.videoDndWrap-select {
    background-color: #aaaaaa;
}


.videoDndWrap-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3px;
    padding: 0px 10px;
}

.videoDndWrap-img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    min-height: 110px;
    max-height: 110px;
}

.videoDndWrap-imgTitle {
    border-width: 0px;
    width: 100%;
    font-size: 0.9em;
    color: #3e3e3e;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.videoDndWrap-del {
    font-size: 0.8em;
    color: #df0000;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    padding: 3px;
}



/* Sales.tx TABLE *************************************************************************** */
.Pay-table {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 3fr; */
    grid-template-columns: repeat(4, auto);
    /* max-width: 100vw; */
    padding-left: 17px;
    padding-right: 14px;
    table-layout: auto;
    width: 100%;
}

.Sales-table {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 3fr; */
    grid-template-columns: repeat(5, auto);
    /* max-width: 100vw; */
    padding-left: 17px;
    padding-right: 14px;
    table-layout: auto;
    width: 100%;
}

.Sales-table-cd {
    min-width: 120px;
    /* max-width: 300px; */
    color: #000000;
    background: #ffffff;
    padding: 16px 15px;
    border-style: solid;
    border-width: 0px;
    border-bottom-color: #e6e6e6;
    border-bottom-width: 0.5px;
}

.Sales-table-header {
    font-weight: bold;
    color: #000000;
    background: #ebebeb;
    padding: 12px 15px;
}

/* Sales.tx TABLE END *************************************************************************** */

.container-root {
    width: 100%;
    /* width: calc(100% - 40px); */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;
}

.loader-containner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 5px solid #f3f3f3;
    /* Light grey */
    border-top: 5px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}















/* 햄버거 메뉴 css : https://codepen.io/designcouch/pen/Atyop */

.nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
    width: 20px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
    display: block;
    position: absolute;
    height: 2.5px;
    width: 100%;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.nav-icon1 span:nth-child(1) {
    top: 0px;
}

.nav-icon1 span:nth-child(2) {
    top: 7px;
}

.nav-icon1 span:nth-child(3) {
    top: 14px;
}

.nav-icon1open span:nth-child(1) {
    top: 7px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.nav-icon1open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.nav-icon1open span:nth-child(3) {
    top: 7px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

/* ******************************************************************************************* */